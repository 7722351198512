const content = {
  title: 'Impressum',
  headvisualHeadline: 'Impressum',
  impressumPart1: {
    headline: 'Impressum',
    paragraphs: [
      '<strong>Herausgeber:</strong></br></br>',
      'NÜRNBERGER Business Beratungs GmbH</br>Geschäftsführer: Daniel Pazanin, Fränzi Wegert</br>Sitz: Nürnberg</br>Registergericht: Amtsgericht Nürnberg HRB 8271</br>Umsatzsteuer-Identifikationsnummer: DE 81 14 61 986</br></br>',
      'Anschrift der Gesellschaft:</br>',
      'Ostendstraße 100</br>90334 Nürnberg</br></br>',
      'Telefon: +49 911 531-4545</br>E-Mail: NBB-Dienstleistungen@nuernberger.de</br></br>'
    ]
  },
  hrefWebsite: {
    href: 'https://nbb-infothek.de/',
    linkText: 'https://nbb-infothek.de/'
  },
  impressumPart2: {
    paragraphs: [
      'Zuständige Aufsichtsbehörde:',
      'Industrie- und Handelskammer für München und Oberbayern</br>Max-Joseph-Straße 280333 München</br>Telefon +49 89 5116-0</br>Fax +49 89 5116-306</br>'
    ]
  },
  nutzungsbedingungen: {
    headline: 'Nutzungsbedingungen',
    paragraphs: [
      '<strong>Rechtliche Hinweise</strong></br></br>',
      'Die Inhalte auf dieser Internetseite, der Hauptseite sowie allen Unterseiten werden sorgfältig erarbeitet und laufend gepflegt. Die NÜRNBERGER Business Beratungs GmbH (nachfolgend: NBB) ist dabei bemüht, diese Inhalte vollständig und aktuell zu halten. Nichtsdestotrotz können fehlerhafte Inhalte enthalten sein, weshalb die NBB keine Gewähr für Vollständigkeit, Aktualität und Richtigkeit der Inhalte übernimmt. Die Inhalte sind bewusst allgemein formuliert und stellen keine Beratung des Nutzers für den Einzelfall dar und können keine Einzelfallberatung ersetzen. Die NBB haftet nicht für Schäden, die daraus resultieren, dass auf die Vollständigkeit, Aktualität und Richtigkeit der Inhalte dieser Internetseite vertraut wurde. Die NBB behält sich das Recht vor, jederzeit Änderungen, Ergänzungen oder Löschungen der Inhalte dieser Internetseite vorzunehmen.</br></br>',
      '<strong>Urheberrechte / Marken und Logos</strong></br></br>',
      'Die Inhalte auf dieser Internetseite, der Hauptseite sowie allen Unterseiten sind urheberrechtlich geschützt. Ohne vorherige schriftliche Zustimmung der NBB dürfen Inhalte, insbesondere Texte, Bilder, Grafiken etc., nicht genutzt oder verwendet werden. Dies gilt für jegliche Nutzungen, also ganz oder teilweise, privat oder kommerziell.',
      'Die Marken und Logos auf der Internetseite der NBB sind marken- und urheberrechtlich geschützt. Ohne vorherige schriftliche Zustimmung der NBB dürfen Marken oder Logos nicht genutzt werden. Dies gilt für jegliche Nutzungen, also ganz oder teilweise, privat oder kommerziell.</br></br>',
      '<strong>Hyperlinks und Verweise auf Internetseiten Dritter</strong></br></br>',
      'Wenn Sie Internetseiten Dritter aufrufen, verlassen Sie insoweit die Internetseite der NBB. Die NBB ist für den Inhalt von Internetseiten Dritter nicht verantwortlich. Die NBB haftet nicht für den Inhalt von Internetseiten Dritter. Die NBB übernimmt keine weitere Haftung für die Vollständigkeit, Aktualität oder Richtigkeit von Internetseiten Dritter, soweit hierauf mit einem Hyperlink oder sonstigem Verweis referenziert wird. Die NBB macht sich die Inhalte der Internetseite Dritter nicht zu eigen.'
    ]
  },
  hrefIhkEmail: {
    href: 'mailto:info@ihk-akademie-muenchen.de',
    linkText: 'info@ihk-akademie-muenchen.de'
  },
  hrefIhkWebsite: {
    href: 'https://www.ihk-akademie-muenchen.de',
    linkText: 'www.ihk-akademie-muenchen.de'
  }
};

export default content;
