import React from 'react';

import { graphql } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, Paragraphs } from 'src/components';

import content from 'src/content/impressum';

const ImpressumPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
  >
    <section className="text-dark-blue">
      <SectionHead />
      <div className="container container--simple">
        <Paragraphs items={content.impressumPart1.paragraphs} innerHTML />
        <a
          href={content.hrefWebsite.href}
          className="text-dark-blue font-bold hover:underline"
        >
          {content.hrefWebsite.linkText}
        </a>
        <br></br>
        <br></br>
        <Paragraphs items={content.impressumPart2.paragraphs} innerHTML />
        <a
          href={content.hrefIhkEmail.href}
          className="text-dark-blue font-bold hover:underline"
        >
          {content.hrefIhkEmail.linkText}
        </a>
        <br></br>
        <a
          href={content.hrefIhkWebsite.href}
          className="text-dark-blue font-bold hover:underline"
        >
          {content.hrefIhkWebsite.linkText}
        </a>
      </div>
    </section>
    <section className="text-dark-blue">
      <SectionHead headline={content.nutzungsbedingungen.headline} />
      <div className="container container--simple">
        <Paragraphs items={content.nutzungsbedingungen.paragraphs} innerHTML />
      </div>
    </section>
  </MainLayout>
);

export default ImpressumPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-leaning-on-window-with-coffee.jpg" }
    ) {
      ...image
    }
    womanSmilingWithTablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiling-with-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
